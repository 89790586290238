<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white">
        <div class="">
          <Overview></Overview>
        </div>
        <div class="mt-8">
          <RevenueByBrand></RevenueByBrand>
        </div>
        <div class="mt-8">
          <RevenueByChannel></RevenueByChannel>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import RevenueByChannel from './revenueByChannel.vue';
import RevenueByBrand from './revenueByBrand.vue';
import Overview from './overview.vue'
export default {
  components: {
    Overview,
    RevenueByBrand,
    RevenueByChannel
},
  data () {
    return {
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'Consumer Sales ◾ Overview'
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>
